<template>
  <div>
    <div
      v-if="Object.keys(promotionpage).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <UserToolbar
        v-if="account && Object.keys(account).length !== 0"
        :account="account"
      />
      <b-form @submit.prevent>
        <validation-observer ref="validationPromotionPageRules">
          <b-tabs
            pills
          >
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Main settings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Main settings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Title"
                      label-for="i-title"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="title"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-title"
                            v-model="promotionpage.title"
                            placeholder="Title"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="URL"
                      label-for="i-url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="url"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-url"
                            v-model="promotionpage.url"
                            placeholder="URL"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>
                        <b-link @click="$refs.refInputEl.$el.click()">
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="`${$promotionPageImgUrl}/${promotionpage.image_id}`"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <!-- upload button -->
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="$refs.refInputEl.$el.click()"
                        >
                          Upload
                        </b-button>
                        <b-form-file
                          ref="refInputEl"
                          v-model="mediaFile"
                          accept=".jpg, .png, .gif"
                          :hidden="true"
                          plain
                          @input="uploadPromotionPageImg"
                        />
                        <!--/ upload button -->

                        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('sites.status')"
                      label-for="i-status"
                    >
                      <b-form-select
                        v-model="status"
                        :options="statusesTranslate"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: `/users/${site.user_id}/sites/${site._id}` }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="promotionPageUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="ShieldIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('sites.adminsettings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('sites.adminsettings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="is_moderate"
                        value="1"
                      >
                        {{ $t('sites.moderated') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: `/users/${site.user_id}/sites/${site._id}` }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('Back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="promotionPageUpdate"
                    >
                      <span class="align-middle">{{ $t('Save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'
import {
  BRow,
  BCol,
  BSpinner,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BButton,
  BFormCheckbox,
  VBTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
} from 'bootstrap-vue'
import {
  required,
  integer,
  min,
} from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import wait from '@/utils/wait'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Breadcrumbs,
    BRow,
    BCol,
    BSpinner,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      pageTitle: '',
      min,
      required,
      integer,
      checking: '',
      mediaFile: [],
      promotion: [],
      promotionpage: [],
      status: {},
      site: [],
      account: {},
      is_moderate: [],
      breadcrumbItems: [],
      ...options,
    }
  },
  computed: {
    statusesTranslate() {
      return this.statuses.map(s => {
        const item = {}
        item.value = s.value
        item.text = this.$t(`selects.statuses.${s.value}`)
        return item
      })
    },
  },
  created() {
    this.getPromotionData()
    this.getPromotionpageData()
  },
  methods: {
    async getPromotionData() {
      const responseData = await useJwt.getPromotion(this.$route.params.promotionId)
      this.promotion = responseData.data.promotion || {}
    },
    async getPromotionpageData() {
      const responseData = await useJwt.getPromotionPage(this.$route.params.promotionpageId)
      this.promotionpage = responseData.data.promotionpage || []
      if (Object.keys(this.promotionpage).length) {
        this.getUser(this.promotionpage.user_id)
        this.getSiteData(this.promotionpage.site_id)
        if (this.promotionpage.status !== '') {
          this.status = this.promotionpage.status
        }
        if (this.promotionpage.is_moderate) {
          this.is_moderate.push(1)
        }
      }
    },
    async getSiteData(id) {
      const responseData = await useJwt.getSite(id)
      this.site = responseData.data.site || []
      this.pageTitle = this.site.title
      this.setBreadcrumbs()
    },
    async getUser(id) {
      const responseData = await useJwt.getUser(id)
      this.account = responseData.data.account || []
    },
    setBreadcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users/',
        },
        {
          text: this.account.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: this.$t('page.menu.sites'),
          to: `/users/${this.$route.params.userId}/sites/`,
        },
        {
          text: this.site.title,
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}`,
        },
        {
          text: this.$t('Promotions'),
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}/promotions`,
        },
        {
          text: this.promotion.title,
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}/promotions/${this.$route.params.promotionId}`,
        },
        {
          text: this.promotionpage.title,
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}/promotions/${this.$route.params.promotionId}/promotionpages/${this.promotionpage._id}`,
        },
        {
          text: this.$t('Settings'),
          active: true,
        },
      ]
    },
    promotionPageUpdate() {
      this.$refs.validationPromotionPageRules.validate().then(success => {
        if (success) {
          this.promotionpage.status = this.status
          this.promotionpage.is_moderate = this.is_moderate
          useJwt.setPromotionPage(this.promotionpage._id, this.promotionpage)
          this.$router.push(`/users/${this.site.user_id}/sites/${this.site._id}/promotions/${this.$route.params.promotionId}`)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Promotion page saved',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    async uploadPromotionPageImg() {
      const formData = new FormData()
      formData.append('files', this.$refs.refInputEl.files[0])
      const response = await useJwt.uploadPromotionPageImg(this.promotionpage._id, formData)
        .then(wait(1500))
      if (response) {
        this.promotionpage.image_id = response.data.image_id
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }
    },
  },
}
</script>
<style scoped>
  .select-append {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select-append + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>
